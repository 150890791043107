'use client';

import OopsError from './components/OopsError';

interface ErrorProps {
  error: Error & { digest?: string };
  reset: () => void;
}

const GlobalError = ({ error }: ErrorProps) => {
  return <OopsError error={error} />;
};

export default GlobalError;
